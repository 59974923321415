import { tns } from 'tiny-slider'
let slider = tns({
    container: '.gallery',
    swipeAngle: false,
    items: 3,
    autoplay: false,
    controls: false,
    nav: false,
})

let arrows = [...document.getElementsByClassName('arrow')]
console.log(arrows)

arrows.forEach(arrow => {
    arrow.addEventListener('click', function () {
        if(arrow.classList.contains('arrow-next')) {
            slider.goTo('next')
        } else {
            slider.goTo('prev')
        }
    })
})

$('.plus').on('click', function (e) {
    const table = this.parentNode.nextSibling.nextSibling
    if(table) {
        if(table.classList.contains('active')) {
            table.classList.remove('active')
            $(table).slideUp()
        } else {
            table.classList.add('active')
            $(table).slideDown()
        }
    }
})

$('a.nav-link').on('click', function (e) {
    e.preventDefault()
    scrollToId($(this).attr('href'))
})

function scrollToId(id) {
    const yOffset = $('nav#navbar').height() + 16
    const element = document.querySelector(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
}
